import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
    root: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 20,
    },
    textfield: {
        maxWidth: 400,
        width: '100%',
    },
    button: {
        maxWidth: 400,
        width: '100%',
        padding: '10px 0',
    }
})

const AuthForm = (props) => {
    
    const {setKey, error} = props
    const classes = useStyles()
    const [value, setValue] = React.useState('')

    const onKeyChanged = e => setValue(e.target.value)
    const onClick = () => {
        if (value.length > 0 && setKey) {
            setKey(value)
        }
    }

    return (
        <div className={classes.root}>
            <TextField 
                label="Key"
                placeholder="Type key here"
                className={classes.textfield}
                variant="outlined"
                focused
                value={value}
                onChange={onKeyChanged}
                error={error}
            />
            <Button 
                variant="contained"
                className={classes.button}
                color="primary"
                onClick={onClick}
            >
                GO
            </Button>
        </div>
    )
}

AuthForm.propTypes = {
    setKey: PropTypes.func.isRequired
}

export default AuthForm