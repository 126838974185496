import React, {useState, useEffect} from 'react'
import AuthForm from './AuthForm'
import Loading from './Loading'
import UserList from './UserList'
import UserProgress from './UserProgress'
import {fetchCourse, fetchUsers} from './Api'

import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

const State = {
  Idle: 1,
  Signing: 2,
  Signed: 3,
}

const TabPanel = ({index, value, children, ...other}) => {
  if (index !== value) return null
  return <div {...other}>{children}</div>
}

const App = () => {

  const [key, setKey] = useState(window.localStorage.getItem('key'))
  const [state, setState] = useState(State.Idle)
  const [users, setUsers] = useState([])
  const [lastUpdatedAt, setLastUpdatedAt] = useState(null)
  const [tab, setTab] = useState(0)
  const [course, setCourse] = useState(null)

  const updateUsers = async () => {
    const response = await fetchUsers(key) 
    if (response.status === 200) {
      setUsers(response.body)
      setLastUpdatedAt(new Date())
    }
  }

  useEffect(async () => {
    if (key) {
      if (state === State.Idle || state === State.Failed) {
        setState(State.Signing)
        let response = await fetchCourse(key)
        if (response.status === 200) {
          setState(State.Signed)
          setCourse(response.body[0])
          window.localStorage.setItem('key', key)

          await updateUsers()
          const timer = setTimeout(async () => {
            await updateUsers()
          }, 10000)
          return () => clearTimeout(timer)
        }
        else {
          setState(State.Failed)
          setKey(null)
        }
      }
    }

  }, [key])

  if (state === State.Idle || state == State.Failed) {
    return <AuthForm setKey={k => setKey(k)} error={state == State.Failed}/>
  }
  else if (state === State.Signing) {
    return <Loading/>
  }
  
  return (
    <>
      <AppBar position="static" color="transparent">
          <Tabs value={tab} onChange={(e, v) => setTab(v)}>
            <Tab label={`Users (${users.length})`}/>
            <Tab label={`Users progress`}/>
          </Tabs>
          <TabPanel index={0} value={tab}>
            <UserList users={users} updateUsers={updateUsers}/>
          </TabPanel>
          <TabPanel index={1} value={tab}>
            <UserProgress users={users} course={course}/>
          </TabPanel>
      </AppBar> 
    </>
  )
}

export default App;
