import React, { useMemo, useState } from 'react'

import InputBase from '@material-ui/core/InputBase'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    bar: {
        backgroundColor: '#f5f5f5',
        padding: '10px 20px'
    },
    filter: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
}))

const UserProgress = props => {
    const {users, course} = props
    const {units} = course

    const lessonsById = useMemo(() => {
        let values = {}
        units.forEach(u => {
            if (u.lessons) {
                u.lessons.forEach(l => {
                    values[l.id] = {...l, unit_id: u.id}
                })
            }
        })
        return values
    }, [course])

    const unitsById = useMemo(() => {
        let values = {}
        units.forEach(u => {
            values[u.id] = u
        })
        return values
    }, [course])

    const [filters, setFilters] = useState([])
    const classes = useStyles()

    const filterFunction = user => {
        if (filters.length === 0) {
            return true
        }
        for(let i = 0; i < filters.length; ++i) {
            let f = filters[i].trim().toLowerCase()
            let n = user.full_name.toLowerCase()
            if (n.startsWith(f)) {
                return true
            }
        }
        return false
    }

    return (
        <div>
            <div className={classes.bar}>
                <Paper component="form" className={classes.filter}>
                    <InputBase
                        placeholder="Filter: list names separated by comma"
                        className={classes.input}
                        value={filters.join(',')}
                        onChange={e => setFilters(e.target.value.split(','))}
                    />
                </Paper>
            </div>

            <TableContainer component={Paper}>
                <Table style={{minWidth: 300}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>User</TableCell>
                            <TableCell>Unit</TableCell>
                            <TableCell>Lesson</TableCell>
                            <TableCell>Progress</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.filter(filterFunction).map(user => {
                            if (!user.progress) {
                                return null
                            }
                            return user.progress.sort((p1, p2) => (p1[0] - p2[0])).map(p=> {
                                const lesson = lessonsById[p[0]]
                                const unit = lesson ? unitsById[lesson.unit_id] : null
    
                                return (
                                    <TableRow>
                                        <TableCell>{user.full_name}</TableCell>
                                        <TableCell>{unit ? unit.title : ''}</TableCell>
                                        <TableCell>{lesson ? `${lesson.order}. ${lesson.title}` : ''}</TableCell>
                                        <TableCell>{p[1]}%</TableCell>
                                    </TableRow>
                                )
                            })
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default UserProgress