import React, {useState, useRef} from 'react'

import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { changeSubscriptionPeriod, changeUserPassword } from './Api';


const Packages = {
    0: 'Individual',
    1: 'Premium',
    2: 'VIP'
}

const sortById = (u1, u2, asc) => {
    return asc ? u1.id - u2.id : u2.id - u1.id
}
const sortByName = (u1, u2, asc) => {
    if (u1.full_name === u2.full_name) {
        return 0
    }
    else {
        return u1.full_name < u2.full_name ? (asc ? -1 : 1) : (asc ? 1 : -1)
    }
}
const sortByEmail = (u1, u2, asc) => {
    if (u1.email === u2.email) {
        return 0
    }
    else {
        return u1.email < u2.email ? (asc ? -1 : 1) : (asc ? 1 : -1)
    }
}
const sortByRegisteredAt = (u1, u2, asc) => {
    if (u1.registered_at === u2.registered_at) {
        return 0
    }
    else {
        return u1.registered_at < u2.registered_at ? (asc ? -1 : 1) : (asc ? 1 : -1)
    }
}


const UserList = props => {
    const {users, updateUsers} = props
    
    const [sortColumn, setSortColumn] = useState('id')
	const [asc, setAsc] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null)
	const [userID, setUserID] = useState(null)
	const [isSubscriptionOpen, setSubscriptionOpen] = useState(false)
	const [isChangePasswordOpen, setChangePasswordOpen] = useState(false)
	const isActionMenuOpen = anchorEl !== null

	const openActionMenu = (event, userID) => {
		setAnchorEl(event.currentTarget)
		setUserID(userID)
	}

	const closeActionMenu = () => {
		setAnchorEl(null)
	}

    const dateFormatter = Intl.DateTimeFormat('en-US', {
        dateStyle: 'long', timeStyle: 'short'
    })

    const handleSortSelection = column => {
        if (column === sortColumn) {
            setAsc(v => !v)
        }
        else {
            setSortColumn(column)
        }
    } 

    // id, name, email, registered_at
    const sort = (u1, u2) => {
        switch (sortColumn) {
            case 'id':
                return sortById(u1, u2, asc)
            case 'name': 
                return sortByName(u1, u2, asc)
            case 'email':
                return sortByEmail(u1, u2, asc)
            case 'registered_at':
                return sortByRegisteredAt(u1, u2, asc)
            default:
                return 0
        }
    }
    
	return (
		<>
			<TableContainer component={Paper}>
				<Table style={{minWidth: 300}}>
					<TableHead>
						<TableRow>
							<TableCell>
								<TableSortLabel 
									onClick={() => handleSortSelection('id')}
									active={sortColumn === 'id'}
									direction={asc ? "asc" : "desc"}
								>
									ID
								</TableSortLabel>
							</TableCell>
							<TableCell>
								<TableSortLabel 
									onClick={() => handleSortSelection('name')}
									active={sortColumn === 'name'}
									direction={asc ? "asc" : "desc"}
								>
									Full Name
								</TableSortLabel>
							</TableCell>
							<TableCell>
								<TableSortLabel 
									onClick={() => handleSortSelection('email')}
									active={sortColumn === 'email'}
									direction={asc ? "asc" : "desc"}
								>
									Email
								</TableSortLabel>
							</TableCell>
							<TableCell>
								<TableSortLabel 
									onClick={() => handleSortSelection('phone_number')}
									active={sortColumn === 'phone_number'}
									direction={asc ? "asc" : "desc"}
								>
									Phone
								</TableSortLabel>
							</TableCell>
							<TableCell>
								Password
							</TableCell>
							<TableCell>
								<TableSortLabel 
									onClick={() => handleSortSelection('registered_at')}
									active={sortColumn === 'registered_at'}
									direction={asc ? "asc" : "desc"}
								>
									Registered at
								</TableSortLabel>
							</TableCell>
							<TableCell>
								Package
							</TableCell>
							<TableCell>
								Action
							</TableCell>
							<TableCell>
								Purchase start date
							</TableCell>
							<TableCell>
								Purchase end date
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{users.sort(sort).map(user => {
							return (
								<TableRow key={user.id}>
									<TableCell>{user.id}</TableCell>
									<TableCell>{user.full_name}</TableCell>
									<TableCell>{user.email}</TableCell>
									<TableCell>{user.phone_number}</TableCell>
									<TableCell>{user.password}</TableCell>
									<TableCell>{dateFormatter.format(new Date(user.registered_at))}</TableCell>
									<TableCell>{typeof user.package_id === 'number' ? Packages[user.package_id] : '-'}</TableCell>
									<TableCell>
										<IconButton
											onClick={e => openActionMenu(e, user.id)}
											color="primary"
										><MenuIcon /></IconButton>
									</TableCell>
									<TableCell>{user.purchase_date ? dateFormatter.format(new Date(user.purchase_date)) : '-'}</TableCell>
									<TableCell>{user.purchase_date ? dateFormatter.format(new Date(user.purchase_end_date)) : '-'}</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={isActionMenuOpen}
				onClose={closeActionMenu}
				MenuListProps={{
				'aria-labelledby': 'basic-button',
				}}
			>
				<MenuItem onClick={() => { setSubscriptionOpen(true); closeActionMenu(); }}>Change subscription</MenuItem>
				<MenuItem onClick={() => { setChangePasswordOpen(true); closeActionMenu(); }}>Change password</MenuItem>
			</Menu>
			<SetSubsciptionDialog
				isOpen={isSubscriptionOpen}
				onClose={() => setSubscriptionOpen(false)}
				userID={userID}
				updateUsers={updateUsers}
			/>
			<SetPasswordDialog
				isOpen={isChangePasswordOpen}
				onClose={() => setChangePasswordOpen(false)}
				userID={userID}
				updateUsers={updateUsers}
			/>
		</>
    )
}


const SetSubsciptionDialog = ({ userID, isOpen, onClose, updateUsers }) => {
	const [key, setKey] = useState(window.localStorage.getItem('key'))
	const now = new Date()
	const [start, setStart] = useState(now.toISOString().slice(0, 10))
	const [end, setEnd] = useState(new Date(now.setMonth(now.getMonth() + 3)).toISOString().slice(0, 10))
	
	const handleSave = async () => {
		try {
			const res = await changeSubscriptionPeriod(key, userID, start, end)
			onClose()
			if (res) {
				await updateUsers()
			}
		}
		catch (e) {
			console.error(e)
		}
	}

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogTitle>Change subscription date</DialogTitle>
			<DialogContent>
				<TextField
					margin="dense"
					id="name"
					label="Starting from"
					variant="standard"
					placeholder="ex: 2023-08-25"
					value={start}
					onChange={e => setStart(e.target.value)}
				/>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Starting from"
					variant="standard"
					placeholder="ex: 2023-08-25"
					value={end}
					onChange={e => setEnd(e.target.value)}
				/>

			</DialogContent>
			<DialogActions>
			<Button onClick={onClose}>Cancel</Button>
			<Button onClick={handleSave}>Save</Button>
			</DialogActions>
		</Dialog>
	)

}

const SetPasswordDialog = ({ userID, isOpen, onClose, updateUsers }) => {
	
	const [key, setKey] = useState(window.localStorage.getItem('key'))
	const [password, setPassword] = useState('')
	
	const handleSave = async () => {
		try {
			const res = await changeUserPassword(key, userID, password)
			onClose()
			if (res) {
				await updateUsers()
			}
		}
		catch (e) {
			console.error(e)
		}
	}

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogTitle>Change subscription date</DialogTitle>
			<DialogContent>
				<TextField
					margin="dense"
					id="name"
					label="Password"
					variant="standard"
					fullWidth
					autoFocus
					value={password}
					onChange={e => setPassword(e.target.value)}
				/>
			</DialogContent>
			<DialogActions>
			<Button onClick={onClose}>Cancel</Button>
			<Button onClick={handleSave}>Save</Button>
			</DialogActions>
		</Dialog>
	)

}



UserList.propTypes = {
    users: PropTypes.array.isRequired
}

export default UserList