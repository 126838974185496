import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles({
    root: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 20,
    }
})

const Loading = () => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <CircularProgress size={100}/>
        </div>
    )
}


export default Loading