
const BaseUrl = 'https://api.edvi.uz'
// const BaseUrl = 'http://localhost:5500'

/**
 * 
 * @param {string} token 
 * @returns {Promise<{status: number, body: []}>}
 */
 export const fetchCourse = async token => {
    try {
        const response = await fetch(`${BaseUrl}/admin/course`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        if (response.status === 200) {
            const {success, result, error} = await response.json()
            if (success) {
                return {status: 200, body: result}
            }
            console.error('fetchCourse() failed', error)
            return {status: 1, body: []}
        }
        return {status: response.status}
    }
    catch(e) {
        return {status: 0}
    }
}

/**
 * 
 * @param {string} token 
 * @returns {Promise<{status: number, body: []}>}
 */
export const fetchUsers = async token => {
    try {
        const response = await fetch(`${BaseUrl}/admin/users`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        if (response.status === 200) {
            const {success, result, error} = await response.json()
            if (success) {
                return {status: 200, body: result}
            }
            console.error('fetchUsers() failed', error)
            return {status: 1, body: []}
        }
        return {status: response.status}
    }
    catch(e) {
        return {status: 0}
    }
}


/**
 * 
 * @param {string} token 
 * @param {string} userID 
 * @param {Date} startDate 
 * @param {Date} endDate 
 * @returns 
 */
export const changeSubscriptionPeriod = async (token, userID, startDate, endDate) => {
	try {
		const response = await fetch(`${BaseUrl}/admin/users/${userID}/subscription?start=${startDate}&end=${endDate}`, {
			method: 'post',
            headers: {
                'Authorization': `Bearer ${token}`
			}
        })
        if (response.status === 200) {
            return true
		}
		console.error('changeSubscriptionPeriod', response)
		return false
    }
	catch (e) {
		console.error('changeSubscriptionPeriod', e.message)
		return false
    }
}

export const changeUserPassword = async (token, userID, password) => {
	try {
		const response = await fetch(`${BaseUrl}/admin/users/${userID}/password`, {
			method: 'post',
            headers: {
                'Authorization': `Bearer ${token}`
			},
			body: password
        })
        if (response.status === 200) {
            return true
		}
		console.error('changePassword', response)
       	return false
    }
    catch(e) {
		console.error('changePassword', e.message)
		return false
    }
}